@font-face {
  font-family: "Font Awesome 5 Brands";
  font-style: normal;
  font-weight: normal;
  font-display: auto;
  src: url("../fonts/@fortawesome/fa-brands-400.eot");
  src: url("../fonts/@fortawesome/fa-brands-400.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/@fortawesome/fa-brands-400.woff2") format("woff2"),
    url("../fonts/@fortawesome/fa-brands-400.woff") format("woff"),
    url("../fonts/@fortawesome/fa-brands-400.ttf") format("truetype"),
    url("../fonts/@fortawesome/fa-brands-400.svg#fontawesome") format("svg");
}

.fab {
  font-family: "Font Awesome 5 Brands";
}

@font-face {
  font-family: "Font Awesome 5 Free";
  font-style: normal;
  font-weight: 400;
  font-display: auto;
  src: url("../fonts/@fortawesome/fa-regular-400.eot");
  src: url("../fonts/@fortawesome/fa-regular-400.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/@fortawesome/fa-regular-400.woff2") format("woff2"),
    url("../fonts/@fortawesome/fa-regular-400.woff") format("woff"),
    url("../fonts/@fortawesome/fa-regular-400.ttf") format("truetype"),
    url("../fonts/@fortawesome/fa-regular-400.svg#fontawesome") format("svg");
}

.far {
  font-weight: 400;
}

@font-face {
  font-family: "Font Awesome 5 Free";
  font-style: normal;
  font-weight: 900;
  font-display: auto;
  src: url("../fonts/@fortawesome/fa-solid-900.eot");
  src: url("../fonts/@fortawesome/fa-solid-900.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/@fortawesome/fa-solid-900.woff2") format("woff2"),
    url("../fonts/@fortawesome/fa-solid-900.woff") format("woff"),
    url("../fonts/@fortawesome/fa-solid-900.ttf") format("truetype"),
    url("../fonts/@fortawesome/fa-solid-900.svg#fontawesome") format("svg");
}

body {
  color: #000000 !important;
}

.kt-footer .kt-footer__bottom {
  padding: 1rem 0;
}

.kt-footer .kt-footer__bottom .kt-footer__copyright {
  color: #bcbec9;
}

.kt-scrolltop {
  background: #23bcee;
}

.kt-font-danger {
  color: #fd2727 !important;
}

.kt-bg-danger {
  background-color: #fd2727 !important;
}

.pointer {
  cursor: pointer;
}

.kt-widget16 .kt-widget16__items .kt-widget16__item .kt-widget16__date {
  font-weight: 600;
}

.logo {
  width: 110px;
}

.alert.alert-success {
  background: #078f1e !important;
  border: 1px solid #078f1e !important;
}

.kt-widget17 .kt-widget17__stats {
  margin: auto !important;
  width: 100% !important;
}

.kt-widget17
  .kt-widget17__stats
  .kt-widget17__items
  .kt-widget17__item
  .kt-widget17__subtitle {
  font-size: 1.6rem !important;
  text-transform: uppercase !important;
  margin-top: 0px !important;
}

.kt-widget17 .kt-widget17__stats .kt-widget17__items .kt-widget17__item {
  padding: 0rem !important;
  margin: 0rem !important;
  box-shadow: initial !important;
  cursor: initial !important;
}

.kt-subheader .kt-subheader__main .kt-subheader__title {
  font-size: 1.6rem !important;
}

.kt-subheader {
  background-color: initial !important;
}

@media (min-width: 1025px) {
  .kt-content {
    padding: 0px !important;
  }
}

.lblAverageResponseTimeTable {
  color: #828293;
  font-size: 1.1rem;
  font-weight: 400;
  margin-top: 0.55rem;
}

.kt-portlet.kt-portlet--border-bottom-brand {
  border-bottom: 3px solid #23b5b538 !important;
}

.kt-portlet .kt-portlet__head .kt-portlet__head-label .kt-portlet__head-title {
  font-weight: 600 !important;
  text-transform: uppercase !important;
}

.kt-widget26 .kt-widget26__content .kt-widget26__desc {
  color: #828293 !important;
  margin-top: 0 !important;
}

/** AverageResponseTimeTable **/

.kt-widget17 .kt-widget17__stats .kt-widget17__items .kt-widget17__item {
  border: 0px !important;
}

.color-green {
  color: green !important;
}

.color-orange {
  color: orange !important;
}

.color-red {
  color: red !important;
}

.kt-widget17
  .kt-widget17__stats
  .kt-widget17__items
  .kt-widget17__item
  .kt-widget17__desc {
  font-size: 2.5rem !important;
  font-weight: 600 !important;
  color: initial !important;
}

/** QuickAnswersTabel **/

.kt-widget11 .table thead > tr > td {
  color: #5b5c64 !important;
}

/** Botões Menu **/

@media (min-width: 1025px) {
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item.kt-menu__item--here
    > .kt-menu__link
    .kt-menu__link-text {
    color: white !important;
  }
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item.kt-menu__item--here
    > .kt-menu__link {
    background-color: #23b5b5 !important;
  }
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item.kt-menu__item--hover:not(.kt-menu__item--here):not(.kt-menu__item--active)
    > .kt-menu__link
    .kt-menu__link-text,
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item:hover:not(.kt-menu__item--here):not(.kt-menu__item--active)
    > .kt-menu__link
    .kt-menu__link-text {
    color: #23b5b5 !important;
  }
}

.kt-header-menu-wrapper
  .kt-header-menu
  .kt-menu__nav
  > .kt-menu__item
  > .kt-menu__link {
  cursor: pointer !important;
}

/** MENU TOP **/

.kt-header__topbar
  .kt-header__topbar-item.kt-header__topbar-item--user
  .kt-header__topbar-username {
  color: #000000 !important;
}

.kt-header__topbar
  .kt-header__topbar-item.kt-header__topbar-item--user
  .kt-header__topbar-username,
.kt-header__topbar
  .kt-header__topbar-item.kt-header__topbar-item--user
  .kt-header__topbar-welcome {
  font-size: 1.1rem !important;
}

@media (max-width: 1024px) {
  .kt_sticky_toolbar_login {
    display: none !important;
  }
  .kt-header-mobile__toolbar {
    display: none !important;
  }
}

@media (min-width: 1025px) {
  .kt_sticky_toolbar_login {
    display: block !important;
  }
  .kt-sticky-toolbar {
    display: none !important;
  }
  .kt-header .kt-header__top {
    height: 60px !important;
  }
}

.kt-header .kt-header__top {
  background: white;
}

@media (min-width: 1500px) {
  .kt-container {
    height: 70px;
  }
}

.width-90 {
  width: 90px !important;
}

.select-remast {
  height: 26px !important;
  padding: 2px 0px 0px 6px !important;
  width: 70px !important;
}

.btn-secondary:not(:disabled):not(.disabled).active,
.btn-secondary:not(:disabled):not(.disabled):active,
.show > .btn-secondary.dropdown-toggle {
  color: #495057 !important;
  background-color: #d7d7d8 !important;
  border-color: #b7b7d9 !important;
}

.btn.btn-sm i,
.btn-group-sm > .btn i {
  padding-right: 0 !important;
}

table.table.tbl-dashboards thead tr td {
  background: #f0f0f0 !important;
  padding: 6px 10px !important;
  font-weight: 600 !important;
}

table.table.tbl-dashboards tbody tr td {
  padding: 10px !important;
  font-size: 14px;
  font-weight: 400;
  color: #787b8b !important;
}

.table .tbl-dashboards thead > tr > td {
  padding: 10px 10px !important;
}

.kt-widget26 .kt-widget26__content .kt-widget26__number {
  font-size: 2.5rem !important;
}

.align-right {
  text-align: right;
}

.center {
  text-align: center;
}

.min-h-70 {
  min-height: 70px;
}

.min-h-252 {
  min-height: 252px;
}

.tt-initial {
  text-transform: initial !important;
}

.select-ig {
  width: 210px !important;
  display: initial !important;
  margin-right: 10px;
  height: 34px !important;
  padding: 0.35rem 1rem !important;
}

.tbl-p {
  font-size: 14px;
  color: #a7a7ab;
  font-weight: 400;
  margin-top: 10px;
  text-transform: uppercase;
}

/** react dates **/

.DateRangePicker_picker {
  z-index: 2 !important;
}

.DateRangePicker {
  margin-top: 15px;
}

.DateInput {
  width: 108px !important;
}

.DateInput_input {
  padding: 6px 11px 4px !important;
  line-height: 20px !important;
  font-size: 15px !important;
  text-align: center !important;
}

.DateRangePickerInput_arrow_svg {
  width: 18px !important;
}

.fs-16 {
  font-size: 16px;
}

.fs-12 {
  font-size: 12px;
}

.seta {
  font-weight: 900;
  font-size: 14px;
}

.seta-verde {
  color: green;
}

.seta-vermelha {
  color: red;
}

.btn-sm,
.btn-group-sm > .btn {
  padding: 2px 8px 3px 8px !important;
}

table.table.tbl-dashboards tbody tr td.p-btns {
  padding-top: 4px !important;
  padding-bottom: 0px !important;
}

.btn-group button,
.gray {
  color: #71787f !important;
}

.show > .btn-hover-brand,
.btn-hover-brand.active,
.btn-hover-brand:active,
.btn-hover-brand:hover {
  color: #ffffff;
  background-color: #23b5b5 !important;
  border-color: #23b5b5 !important;
}

.btn-hover-brand:hover > i {
  color: #ffffff !important;
}

/** Timeline **/

.kt-timeline-v2 .kt-timeline-v2__items .kt-timeline-v2__item {
  margin-bottom: 1.75rem !important;
}

.kt-timeline-v2
  .kt-timeline-v2__items
  .kt-timeline-v2__item
  .kt-timeline-v2__item-text
  .kt-timeline-v2__item-text-first {
  font-size: 14px !important;
  color: #787b8b !important;
}

.kt-timeline-v2
  .kt-timeline-v2__items
  .kt-timeline-v2__item
  .kt-timeline-v2__item-text
  > span {
  font-weight: 400 !important;
}

.modal-content .form-group p {
  max-height: 15em;
  white-space: pre-wrap;
  overflow: scroll;
  text-overflow: clip;
}
