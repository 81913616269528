.logo {
    width: 110px;
}

#root {
    height: 100%;
}

.login {
    height: 100%;
    background-image: url('../images/bg-login.jpg');
    background-size: cover;
    margin: 0px;
    /*opacity: 0.6;*/
}

.logoImage img {
    width: 150px;
}

.container {
    height: 100%;
}

.contentHight {
    height: 100%;
}

.contentLogin {
    background-color: white;
    border-radius: 10px;
    padding: 30px 30px 40px 40px;
}

.logoImage {
    text-align: center;
    margin-bottom: 30px;
}

.form {
    color: black;
    margin: auto;
}

.formLogin {
    margin-top: 4%;
}

button.btn.buttonLogin {
    width: 100%;
    background-color: #079fab;
    color: white;
}

button.btn.buttonLogin:hover {
    background: #0bbbc9;
}

.styleAlert {
    width: 100%;
    text-align: center;
}

#show {
    font-size: 13px;
}